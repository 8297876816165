<template>
  <div class="">
    <div v-if="method === 'edit'">
      <div class="form-row">
        <DocConfigFormContact
          :docType="docType"
          :templateType="templateType"
          ref="contact"
          class="col-12"
          select="id"
          :types="types"
          v-model="formData.contactId"
          :validations="[
            {text: 'required!', value: $v.formData.contactId.$dirty && !$v.formData.contactId.required}
          ]">
        </DocConfigFormContact>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <sgv-input-check
            label="ค่าเริ่มต้น"
            inline
            v-model="formData.isDefault">
          </sgv-input-check>
        </div>
      </div>

      <button
        class="btn btn-success form-group"
        @click="createData">
        เพิ่ม
      </button>
    </div>

    <div class="">
      <ul>
        <li v-for="child in items" :key="child.id">
          <span
            v-if="method === 'edit'"
            class="px-1 text-danger pointer"
            @click="destroyData(child.id)">
            <fa icon="trash"></fa>
          </span>
          {{child.contact.name}}
          <span v-if="child.isDefault" class="text-success">
            --- ค่าเริ่มต้น
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact'
import {
  LIST_CONTACT_SALES_TYPE,
  LIST_CONTACT_SALES,
  CREATE_CONTACT_SALES,
  DESTROY_CONTACT_SALES,
} from './graph'

export default {
  components: {
    DocConfigFormContact
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formData: {
        contactId: null,
        isDefault: false
      },
      items: [],
      types: []
    }
  },
  validations: {
    formData: {
      contactId: { required }
    }
  },
  apollo: {
    types: {
      query () {
        return LIST_CONTACT_SALES_TYPE(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      }
    },
    items: {
      query () {
        return LIST_CONTACT_SALES(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.parentId,
        }
      },
    }
  },
  methods: {
    createData () {
      this.$apollo.mutate({
        mutation: CREATE_CONTACT_SALES(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          input: this.formData
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(this.$toasted.global.error)
    },
    destroyData (id) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT_SALES(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          docConfigId: id,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
