<template>
  <div class="">
    <div v-if="method === 'edit'">
      <div class="form-row">
        <sgv-input-text
          class="col-12"
          label="วันที่ (cron)"
          v-model="formData.cron"
          :validations="[
            {text: 'required!', value: $v.formData.cron.$dirty && !$v.formData.cron.required}
          ]">
        </sgv-input-text>
      </div>

      <button
        class="btn btn-success form-group"
        @click="createData">
        เพิ่ม
      </button>
    </div>

    <div class="">
      <ul>
        <li v-for="child in items" :key="child.id">
          <span
            v-if="method === 'edit'"
            class="px-1 text-danger pointer"
            @click="destroyData(child.id)">
            <fa icon="trash"></fa>
          </span>
          {{child.cron}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  LIST_CONTACT_BILLED_AT,
  CREATE_CONTACT_BILLED_AT,
  DESTROY_CONTACT_BILLED_AT,
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formData: {
        cron: ''
      },
      items: []
    }
  },
  validations: {
    formData: {
      cron: { required }
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_CONTACT_BILLED_AT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.parentId,
        }
      },
    }
  },
  methods: {
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_CONTACT_BILLED_AT(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          input: this.formData
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.items.refetch()
        this.$v.$reset()
      })
      .catch(this.$toasted.global.error)
    },
    destroyData (id) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT_BILLED_AT(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          docConfigId: id,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
