<template>
  <div class="">
    <div v-if="method === 'edit'">
      <div class="form-row">
        <sgv-input-number
          class="col-12"
          label="เครดิต (วัน)"
          minus
          v-model="formData.salesCredit">
        </sgv-input-number>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <sgv-input-check
            label="ค่าเริ่มต้น"
            inline
            v-model="formData.isDefault">
          </sgv-input-check>
        </div>
      </div>

      <button
        class="btn btn-success form-group"
        @click="createData">
        เพิ่ม
      </button>
    </div>

    <div class="">
      <ul>
        <li v-for="child in items" :key="child.id">
          <span
            v-if="method === 'edit'"
            class="px-1 text-danger pointer"
            @click="destroyData(child.id)">
            <fa icon="trash"></fa>
          </span>
          {{child.salesCredit}} วัน
          <span v-if="child.isDefault" class="text-success">
            --- ค่าเริ่มต้น
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  LIST_CONTACT_SALES_CREDIT,
  CREATE_CONTACT_SALES_CREDIT,
  DESTROY_CONTACT_SALES_CREDIT,
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formData: {
        salesCredit: 0,
        isDefault: false
      },
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_CONTACT_SALES_CREDIT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.parentId,
        }
      },
    }
  },
  methods: {
    createData () {
      this.$apollo.mutate({
        mutation: CREATE_CONTACT_SALES_CREDIT(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          input: this.formData
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(this.$toasted.global.error)
    },
    destroyData (id) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT_SALES_CREDIT(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          docConfigId: id,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
