<template>
  <div class="">
    <div v-if="method === 'edit'">
      <div class="form-row">
        <sgv-input-text
          class="col-12"
          v-model="search"
          label="ค้นหา">
        </sgv-input-text>

        <div class="form-group col-12">
          <sgv-input-check
            label="ค่าเริ่มต้น"
            inline
            v-model="isDefault">
          </sgv-input-check>
        </div>
      </div>

      <div v-if="items.length > 0">
        <ul>
          <li v-for="discountPrice in items" :key="discountPrice.id">
            <span
              v-if="method === 'edit'"
              class="px-1 text-success pointer"
              @click="createData(discountPrice.id)">
              <fa icon="check"></fa>
            </span>
            {{discountPrice.name}}
          </li>
        </ul>
      </div>
    </div>

    <div class="">
      <ul>
        <li v-for="discountPrice in discountPrices" :key="discountPrice.id">
          <span
            v-if="method === 'edit'"
            class="px-1 text-danger pointer"
            @click="destroyData(discountPrice.id)">
            <fa icon="trash"></fa>
          </span>
          {{discountPrice.name}}
          <span v-if="discountPrice.isDefault" class="text-success">
            --- ค่าเริ่มต้น
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  DP_CONTACT_DISCOUNT_PRICE,
  LIST_CONTACT_DISCOUNT_PRICE,
  CREATE_CONTACT_DISCOUNT_PRICE,
  DESTROY_CONTACT_DISCOUNT_PRICE,
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      search: '',
      discountPrices: [],
      items: [],
      isDefault: false
    }
  },
  apollo: {
    items: {
      query () {
        return DP_CONTACT_DISCOUNT_PRICE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            params: {
              search: this.search
            },
            limit: 10,
            order: 'name'
          },
        }
      },
      fetchPolicy: 'no-cache'
    },
    discountPrices: {
      query () {
        return LIST_CONTACT_DISCOUNT_PRICE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.parentId,
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    createData (childId) {
      this.$apollo.mutate({
        mutation: CREATE_CONTACT_DISCOUNT_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          childId,
          isDefault: this.isDefault
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.discountPrices.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData (childId) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT_DISCOUNT_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          childId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.discountPrices.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
