<template>
  <div class="">
    <div v-if="method === 'edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <div v-if="items.length > 0">
        <ul>
          <li v-for="group in items" :key="group.id">
            <span
              v-if="method === 'edit'"
              class="px-1 text-success pointer"
              @click="createData(group.id)">
              <fa icon="check"></fa>
            </span>
            {{group.name}}
          </li>
        </ul>
      </div>
    </div>

    <div class="">
      <ul>
        <li v-for="group in groups" :key="group.id">
          <span
            v-if="method === 'edit'"
            class="px-1 text-danger pointer"
            @click="destroyData(group.id)">
            <fa icon="trash"></fa>
          </span>
          {{group.name}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  DP_CONTACT_GROUP,
  LIST_CONTACT_GROUP,
  CREATE_CONTACT_GROUP,
  DESTROY_CONTACT_GROUP,
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      search: '',
      groups: [],
      items: [],
    }
  },
  apollo: {
    items: {
      query () {
        return DP_CONTACT_GROUP(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            params: {
              search: this.search
            },
            limit: 10,
            order: 'name'
          },
        }
      },
      fetchPolicy: 'no-cache'
    },
    groups: {
      query () {
        return LIST_CONTACT_GROUP(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.parentId,
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    createData (childId) {
      this.$apollo.mutate({
        mutation: CREATE_CONTACT_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          childId,
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.groups.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData (childId) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          childId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.groups.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
